@tailwind base;
@tailwind components;
@tailwind utilities;

.custom-min-h-20 {
  min-height: 5rem;
}

#nav-div {
  box-shadow: 1px 0 5px #888888aa;
  width: 15rem;
  max-width: 15rem;
  min-width: 15rem;
}

#is-dirty-wrapper {
  position: sticky;
  top: 10px;
}

/* Tooltip settings and overrides. */
#user-dd-menu::after {
  border-left: none !important;
  border-right: none !important;
}
#last-x-jots-dd-menu::after {
  border-left: none !important;
  border-right: none !important;
}
#last-x-jots-dd-menu {
  max-width: 220px !important;
}
.__react_component_tooltip.show {
  opacity: 1 !important;
  border: 1px solid #999;
}

/* Quill Over Rides */
.ql-container {
  border: none !important;
  font-size: inherit !important;
  padding: 1.5rem;
  padding-top: 0;
}

.ql-toolbar {
  background-color: #3b83f6;
  border: none !important;
  position: sticky;
  top: 0;
  z-index: 2;
  padding: 1rem !important;
}

.ql-toolbar button {
  border-radius: 2px !important;
  background-color: #75a5f3 !important;
  margin-left: 1px;
  margin-right: 1px;
}

.ql-picker,
.ql-picker-label {
  border-radius: 2px !important;
  background-color: #75a5f3 !important;
  margin-left: 1px;
  margin-right: 1px;
}

.ql-picker-options {
  border-radius: 2px !important;
  border: none !important;
  background-color: inherit !important;
}

.ql-tooltip {
  background-color: #bbb !important;
  z-index: 100;
}

@media (1px <= width <= 500px) {
  .ql-tooltip {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.ql-editor h1 {
  margin-top: 1.15rem !important;
  margin-bottom: 0.5rem !important;
}

.ql-editor h2 {
  margin-top: 0.65rem !important;
  margin-bottom: 0.25rem !important;
}

pre.ql-syntax {
  font-size: 0.9rem !important;
  background-color: #666 !important;
}

/* Fix scroll after paste bug: https://github.com/quilljs/quill/issues/2027#issuecomment-600793529 */
.ql-clipboard {
  position: fixed !important;
  left: 50% !important;
  top: 50% !important;
  opacity: 0;
}
